<template>
    <div id="matrix_background">
		<canvas id="canvas_matrix"></canvas>
	</div>
</template>

<script>
export default {
	mounted()
	{
		this.initMatrix();
	},

	methods : {
		initMatrix()
		{
			var canvas = document.getElementById('canvas_matrix');
			var ctx = canvas.getContext('2d');
			var cols = canvas.width = window.innerWidth;
			var rows = canvas.height = window.innerHeight;
			var matrix = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%프로그래머엔지니어김광중이유진선후율겸";
			var text_bright = "유진후율겸";
			matrix = matrix.split("");

			var font_size = 20;
			var columns = cols / font_size;
			var drops = [];
			for (var x = 0; x < columns; x++)
				drops[x] = 1;

			function draw()
			{
				ctx.fillStyle = "rgba(0, 0, 0, 0.04)";
				ctx.fillRect(0, 0, cols, rows);
				ctx.font = font_size + "px arial";
				for (var i = 0; i < drops.length; i++)
				{
					if(Math.random() > 0.5) continue;
						// drops[i] = 0;
					var text = matrix[Math.floor(Math.random() * matrix.length)];
					if(text_bright.indexOf(text) >= 0) ctx.fillStyle = "#0ff";
					else ctx.fillStyle = "#077";
					ctx.fillText(text, i * font_size, canvas.height - drops[i] * font_size);
					if (drops[i] * font_size > rows && Math.random() > 0.975)
						drops[i] = 0;
					drops[i]++;
				}
			}

			setInterval(draw, 50);
		}
	
	}
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>

<style lang="scss" scoped>
#matrix_background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	overflow: hidden;
	z-index: -1;

	#canvas_matrix {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
</style>
